<template>
  <div class="text-grey">
    <div class="mt-3 mx-3">
      <h4 class="font-weight-bold">Filters</h4>
      <hr />
    </div>
    <div :class="{ openFilterbar: filter }">
      <div v-if="keywordFacet.doc_count > 0">
        <div
          v-for="(keyword, index) in keywordFacet.attribute_name.buckets"
          :key="index"
        >
          <div
            v-if="keyword.key !== 'Description' && keyword.key !== 'Country'"
          >
            <h6
              class="collapse-block-title mt-3 text-orange mx-3"
              v-b-toggle="keyword.key"
            >
              {{ keyword.key }}
              <span class="tx-13"
                ><b-icon-chevron-down></b-icon-chevron-down
              ></span>
            </h6>

            <b-collapse
              :id="keyword.key"
              visible
              accordion="myaccordion1"
              role="tabpanel"
            >
              <div>
                <div>
                  <div
                    class="custom-control custom-checkbox collection-filter-checkbox mx-3"
                    v-for="(item, index) in keyword.attribute_value.buckets"
                    :key="index"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :value="item.key"
                      v-model="applyFilter"
                      :id="item.key"
                      @change="appliedFilter(keyword.key, item.key)"
                    />
                    <label class="custom-control-label" v-bind:for="item.key">{{
                      item.key
                    }}</label>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      value: [0, 0],
      applyFilter: [],
      filter: false,
    };
  },
  computed: {
    ...mapGetters({
      keywordFacet: "search/getKeywordFilters",
    }),
  },
  methods: {
    appliedFilter(key, value) {
      this.$store.dispatch("search/changeKeywordFilter", { key, value });
      this.$emit("allFilters", this.applyFilter);
    },
  },
};
</script>
